import { ISource } from './map';

export enum SelectionType {
  Selection = 'Selection',
  Shape = 'Shape',
}

export enum SelectionUpdate {
  Add = 'Add',
  Reset = 'Reset',
  Remove = 'Remove',
  Replace = 'Replace',
}

export type SelectedFeatureId = string | number;
export type SelectedFeature = { id: SelectedFeatureId; properties: { name: string } };

export interface ISelectionLayer {
  id: string;
  name: string;
  source: ISource;
}

export interface ISelectionFeature {
  id: SelectedFeatureId;
  type: SelectionType;
  name: string;
  layer: ISelectionLayer;
}

export interface IFeatureForDisplay {
  layerId: string;
  featureIds: Array<SelectedFeatureId>;
}

export interface ISelectionLegend {
  agencylocationtypeid: number;
  agencylocationtypename: string;
  count: number;
  percent: number;
  totalcount: number;
  totalpercent: number;
}

interface LegendFeature {
  featureId: string;
  layerId: string;
}

export interface LegendGeometry {
  features: Array<LegendFeature>;
  shapes: Array<string>;
}
