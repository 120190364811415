import { RequestOptions, ResponseError } from '@/types/request';
import request from './request.service';
import config from '@/config/index';
import { sortBy } from 'lodash';

// @ts-ignore
import * as acst from '@acst/missioninsite-apiservice';
import { ISource } from '@/types/map';

import type {
  PersonTypeAddResponse,
  PersonBulkAddInitResponse,
  ReleaseNotesResponse,
  ExternalHelpLinksResponse,
  ListAgenciesResponse,
  ListStudyResponse,
  GetStudyResponse,
  GetStudyHistoryResponse,
  Agency,
  GetAuditUserAgencyMetricsResponse,
  GetAuditUserLoginMetricsResponse,
  GetAuditUserReportMetricsResponse,
  CustomShapesSearchResponse,
  CustomShapesGetResponse,
  ReportJobSearchResponse,
  UserSearchResponse,
  LocationSearchResponse,
  ReportCategoryMap,
  ListStudyStatesResponse,
  PersonGetInfoResponse,
} from '@acst/missioninsite-apiservice/apiservice_pb';

import {
  JobStatus,
  ReportType,
  ReportTypeMap,
  ReportFileType,
  StudyType,
  DataType,
  PredefinedReportId,
  UserApproveDenyResult,
} from '@acst/missioninsite-apiservice/apiservice_pb';
import AbortControllerStorage, { AbortControllerKey } from '@/services/abortController.service';

export const jobStatus = JobStatus;
export const reportType = ReportType;
export type reportTypeMap = ReportTypeMap;
export const studyType = StudyType;
export const userApproveDenyResult = UserApproveDenyResult;
export const reportFileType = ReportFileType;
export const attributeDataType = DataType;
export const ATTRIBUTE_DATATYPE_LIST = 5;
export const predefinedReportId = PredefinedReportId;

import moment from 'moment';
import { stringifyWkt } from '@/utils/shapes/common';
import { ILegend, ILegendRequest } from '@/types/plotting';
import { convertToGeometrySelection } from '@/utils/selection';
import { PhoneType } from '@/types/locations';

import type { LegendGeometry } from '@/types/selection';
import type { LegendRequest } from '@/types/neighborcenter';

const apiServiceCallWrapper = (url: string, body?: Uint8Array, config?: object): Promise<Uint8Array> => {
  const localConfig: RequestOptions = {
    method: 'POST',
    isJson: false,
    headers: {
      'content-type': 'application/protobuf',
    },
  };
  return request(url, { body, ...config, ...localConfig })
    .then(response => response.arrayBuffer())
    .then(buffer => new Uint8Array(buffer));
};

let apiServiceClient: any;

const getApiServiceClient = () => {
  if (!apiServiceClient) {
    apiServiceClient = new acst.ApiserviceClient(apiServiceCallWrapper, config.apiServiceUrl);
  }
  return apiServiceClient;
};

export const getGeoJSON = async (agencyUuid: string, layer: string): Promise<any> => {
  const req = new acst.GetGeoJsonRequest();
  req.setAgencyuuid(agencyUuid);
  req.setLayer(layer);
  return getApiServiceClient().getGeoJson(req);
};

export const getLayerGeoJSON = async (agencyUuid: string, layer: string): Promise<acst.LayerGeoJsonResponse> => {
  const req = new acst.GetGeoJsonRequest();
  req.setAgencyuuid(agencyUuid);
  req.setLayer(layer);
  return getApiServiceClient().layerGetGeoJson(req);
  // .then((res: acst.LayerGeoJsonResponse) => res.toObject());
};

export const getLayerFeatures = async (
  layerId: string,
  isPublished?: boolean,
): Promise<acst.LayerFeatures.AsObject> => {
  const req = new acst.GetFeaturesRequest();

  req.setPublished(!!isPublished);
  req.setLayerid(layerId);

  return getApiServiceClient()
    .layerGetFeatures(req)
    .then((res: acst.LayerFeatures) => res.toObject());
};

export const getStandartLayerFeatures = (
  layerId: number | string,
  stateId: string | number,
): Promise<acst.StandardLayerFeatures.AsObject> => {
  const req = new acst.GetStandardLayerFeaturesRequest();

  req.setLayerid(layerId);

  if (stateId) {
    req.setStateid(stateId);
  }

  return getApiServiceClient()
    .getStandardLayerFeatures(req)
    .then((res: acst.StandardLayerFeatures) => res.toObject());
};

export const standardLayerFeaturesIntersected = (
  layerId: string,
  geometry: GeoJSON.Polygon,
): Promise<acst.StandardLayerFeaturesIntersectedResponse.AsObject> => {
  const req = new acst.StandardLayerFeaturesIntersectedRequest();

  req.setLayerid(layerId);

  const geometrySelection = new acst.GeometrySelection();
  geometrySelection.setShapegeomsList([{ geometry }]);
  req.setGeoms(geometrySelection);

  return getApiServiceClient()
    .StandardLayerFeaturesIntersected(req)
    .then((res: acst.StandardLayerFeaturesIntersectedResponse) => res.toObject());
};

export const getLayerFeature = (
  layerId: string,
  layerFeatureId: number | string,
): Promise<acst.LayerFeature.AsObject> => {
  const req = new acst.LayerFeatureRequest();

  req.setLayerid(layerId);
  req.setLayerfeatureid(layerFeatureId);

  return getApiServiceClient()
    .layerGetFeature(req)
    .then((res: acst.LayerFeature) => res.toObject());
};

export const getLayerStandardFeature = (layerId: string, featureId: string): Promise<acst.LayerFeature.AsObject> => {
  const req = new acst.LayerGetStandardFeatureRequest();

  req.setLayer(layerId);
  req.setFeature(featureId);

  return getApiServiceClient()
    .layerGetStandardFeature(req)
    .then((res: acst.LayerFeature) => res.toObject());
};

export const getReportDownloadLink = (reportjobuuid: string): Promise<acst.ReportDownloadLinkGetResponse.AsObject> => {
  const req = new acst.ReportDownloadLinkGetRequest();
  req.setReportjobuuid(reportjobuuid);
  return getApiServiceClient()
    .reportDownloadLinkGet(req)
    .then((res: acst.ReportDownloadLinkGetResponse) => res.toObject());
};

export const reportJobSearch = (): Promise<ReportJobSearchResponse.AsObject> => {
  const req = new acst.ReportJobSearchRequest();
  return getApiServiceClient()
    .reportJobSearch(req)
    .then((res: ReportJobSearchResponse) => res.toObject());
};

export const getStudyDataByUuid = async (uuid: string): Promise<acst.GetStudyResponse.AsObject> => {
  const req = new acst.StudyRequest();
  req.setUuid(uuid);
  return getApiServiceClient()
    .getStudy(req)
    .then((res: acst.GetStudyResponse) => res.toObject());
};

export const getStudyToken = async (studyUuid: string): Promise<acst.AuthTokens.AsObject> => {
  const req = new acst.TokenForStudyAccessGetRequest();
  req.setStudyuuid(studyUuid);

  return getApiServiceClient()
    .tokenForStudyAccessGet(req)
    .then((res: acst.AuthTokens) => res.toObject());
};

export const getStudyLayers = async (uuid: string): Promise<acst.LayerInfo.AsObject> => {
  const req = new acst.StudyRequest();
  req.setUuid(uuid);
  return getApiServiceClient()
    .getStudyLayers(req)
    .then((res: acst.LayerInfo) => res.toObject());
};

export const releaseNotes = (): Promise<acst.ReleaseNotesResponse.AsObject> =>
  getApiServiceClient()
    .releaseNotes(new acst.EmptyStructure())
    .then((res: ReleaseNotesResponse) => res.toObject());

export const externalHelpLinks = (): Promise<acst.ExternalHelpLinksResponse.AsObject> =>
  getApiServiceClient()
    .externalHelpLinks(new acst.EmptyStructure())
    .then((res: ExternalHelpLinksResponse) => res.toObject());

export const listStudies = (uuid: string): Promise<acst.ListStudyResponse.AsObject> => {
  const request = new acst.ListStudyRequest();
  if (uuid.length) request.setAgencyuuid(uuid);

  return getApiServiceClient()
    .listStudies(request)
    .then((res: ListStudyResponse) => res.toObject());
};
export const getStudy = (uuid: string): Promise<GetStudyResponse.AsObject> => {
  const request = new acst.StudyRequest();
  request.setUuid(uuid);
  return getApiServiceClient()
    .getStudy(request)
    .then((res: GetStudyResponse) => res.toObject());
};

export const listAgencies = (): Promise<ListAgenciesResponse.AsObject> =>
  getApiServiceClient()
    .listAgencies(new acst.EmptyStructure())
    .then((res: ListAgenciesResponse) => res.toObject())
    .then((agencies: ListAgenciesResponse.AsObject) => {
      agencies.agenciesList = sortBy(agencies.agenciesList, v => v.name);
      return agencies;
    });

export const getStudyStatesList = (): Promise<Array<ListStudyStatesResponse.StateInfo.AsObject>> => {
  return getApiServiceClient()
    .listStudyStates(new acst.EmptyStructure())
    .then((res: ListStudyStatesResponse) => res.toObject().statesList);
};

export const sendUserFeedback = (
  message: string,
  feedbackType?: acst.UserErrorRequestTypeMap[keyof acst.UserErrorRequestTypeMap],
) => getApiServiceClient().reportUserError(new acst.UserErrorRequest().setMessage(message).setReqType(feedbackType));

export const getAgency = (uuid: string): Promise<Agency.AsObject> => {
  const req = new acst.GetAgencyRequest();
  req.setAgencyuuid(uuid);

  return getApiServiceClient()
    .getAgency(req)
    .then((res: Agency) => {
      return res.toObject();
    });
};

export const updateAgency = (uuid: string, agencyname: string): Promise<acst.EmptyStructure> => {
  const req = new acst.UpdateAgencyRequest();
  req.setAgencyuuid(uuid);
  req.setAgencyname(agencyname);
  return getApiServiceClient().updateAgency(req);
};

export const locationByCity = (agencyuuid: string): Promise<acst.LocationByCityResponse> => {
  const req = new acst.GetAgencyRequest();
  req.setAgencyuuid(agencyuuid);
  return getApiServiceClient()
    .locationByCity(req)
    .then((res: acst.LocationByCityResponse) => res.toObject());
};

export const getLocation = (locationId: number): Promise<acst.LocationData.AsObject> => {
  const req = new acst.LocationGetRequest();
  req.setLocationid(locationId);
  return getApiServiceClient()
    .locationGet(req)
    .then((res: acst.LocationData) => res.toObject());
};

export const deleteLocation = (locationId: number): Promise<acst.EmptyStructure> => {
  const req = new acst.LocationDeleteRequest();
  req.setLocationid(locationId);
  return getApiServiceClient().locationDelete(req);
};

export const searchLocations = (
  agencyuuid: string,
  geometry?: LegendGeometry | null,
): Promise<acst.LocationSearchResponse.AsObject> => {
  const req = new acst.LocationSearchRequest();

  const geometrySelection = geometry ? convertToGeometrySelection(geometry) : null;

  if (geometrySelection) {
    req.setGeoms(geometrySelection);
  }

  req.setAgencyuuid(agencyuuid);

  return getApiServiceClient()
    .locationSearch(req)
    .then((res: LocationSearchResponse) => res.toObject());
};

export const replaceLocation = (payload: Record<string, any>): Promise<acst.EmptyStructure> => {
  const locationid = payload.locationid;
  const phoneId = -1;

  const req = new acst.LocationData();

  const phone1 = new acst.LocationData.AgencyLocationPhoneNumber();
  phone1.setPhoneid(phoneId);
  phone1.setPhonenumber(payload.phone1);
  phone1.setPhonetype(PhoneType.MAIN_PHONE);

  const phone2 = new acst.LocationData.AgencyLocationPhoneNumber();
  phone2.setPhoneid(phoneId);
  phone2.setPhonenumber(payload.phone2);
  phone2.setPhonetype(PhoneType.OTHER_PHONE);

  const fax = new acst.LocationData.AgencyLocationPhoneNumber();
  fax.setPhoneid(phoneId);
  fax.setPhonenumber(payload.fax);
  fax.setPhonetype(PhoneType.FAX);

  req.setWebsite(payload.website);
  req.setContact(payload.contact);
  req.setNotes(payload.notes);
  req.setEmail(payload.email);
  req.setAddress1(payload.address1);
  req.setAddress2(payload.address2);
  req.setCity(payload.city);
  req.setState(payload.state);
  req.setZipcode(payload.zipcode);
  req.setLatitude(payload.latitude);
  req.setLongitude(payload.longitude);
  req.setPhonesList([phone1, phone2, fax]);

  req.setType(payload.type);

  req.setName(payload.name);
  req.setDescription(payload.description);
  req.setAgencyuuid(payload.agencyuuid);

  if (locationid) {
    req.setLocationid(locationid);

    return getApiServiceClient().locationUpdate(req);
  }

  return getApiServiceClient().locationCreate(req);
};

export const getStudyHistory = (): Promise<GetStudyHistoryResponse.AsObject> =>
  getApiServiceClient()
    .getStudyHistory(new acst.EmptyStructure())
    .then((res: GetStudyHistoryResponse) => res.toObject());

export const deleteStudyHistory = (): Promise<acst.EmptyStructure> =>
  getApiServiceClient().deleteStudyHistory(new acst.EmptyStructure());

export const retrieveLayerFeatures = async (
  studyUuid: string | undefined,
  source: ISource,
  featureName: string,
): Promise<acst.RetrieveLayerFeaturesResponse.AsObject> => {
  if (!studyUuid) return;
  const req = new acst.RetrieveLayerFeaturesRequest();
  const study = new acst.StudyRequest();
  study.setUuid(studyUuid);
  req.setStudy(study);
  const layerSource = new acst.LayerSource();
  layerSource.setType(
    source.type === 'geojson'
      ? acst.LayerSourceType.LAYERSOURCETYPE_GEOJSON
      : acst.LayerSourceType.LAYERSOURCETYPE_MAPBOX,
  );
  layerSource.setLayer(source.layer);
  req.setLayer(layerSource);
  req.setFeature(featureName);
  return getApiServiceClient()
    .retrieveLayerFeatures(req)
    .then((res: acst.RetrieveLayerFeaturesResponse) => res.toObject());
};

export const searchLayerFeatures = async (
  studyUuid: string | undefined,
  source: ISource,
  featureName: string,
  searchString: string,
): Promise<acst.SearchLayerFeaturesResponse.AsObject> => {
  if (!studyUuid) return;
  const req = new acst.SearchLayerFeaturesRequest();
  const layer = new acst.RetrieveLayerFeaturesRequest();
  const study = new acst.StudyRequest();
  study.setUuid(studyUuid);
  layer.setStudy(study);
  const layerSource = new acst.LayerSource();
  layerSource.setType(
    source.type === 'geojson'
      ? acst.LayerSourceType.LAYERSOURCETYPE_GEOJSON
      : acst.LayerSourceType.LAYERSOURCETYPE_MAPBOX,
  );
  layerSource.setLayer(source.layer);
  layer.setLayer(layerSource);
  layer.setFeature(featureName);
  req.setLayer(layer);
  req.setSearch(searchString);
  return getApiServiceClient()
    .searchLayerFeatures(req)
    .then((res: acst.SearchLayerFeaturesResponse) => res.toObject());
};

export const checkToken = async (): Promise<acst.CheckTokenReply.AsObject> => {
  return getApiServiceClient()
    .checkToken(new acst.EmptyStructure())
    .then((res: acst.CheckTokenReply) => res.toObject());
};

export const agencyUsageReport = (
  agencyUuid: string,
  studyUuid: string,
  startTime: string,
  endTime: string,
): Promise<GetAuditUserAgencyMetricsResponse.AsObject> => {
  const req = new acst.GetAuditUserAgencyMetricsRequest();
  req.setAgencyuuid(agencyUuid);
  req.setStudyuuid(studyUuid);
  req.setStarttime_asMoment(moment(startTime).startOf('day'));
  req.setEndtime_asMoment(moment(endTime).endOf('day'));
  return getApiServiceClient()
    .getAuditUserAgencyMetrics(req)
    .then((res: GetAuditUserAgencyMetricsResponse) => res.toObject());
};

export const usersUsageReport = (
  agencyUuid: string,
  studyUuid: string,
  startTime: string,
  endTime: string,
): Promise<GetAuditUserReportMetricsResponse.AsObject> => {
  const req = new acst.GetAuditUserReportMetricsRequest();
  req.setAgencyuuid(agencyUuid);
  req.setStudyuuid(studyUuid);
  req.setStarttime_asMoment(moment(startTime).startOf('day'));
  req.setEndtime_asMoment(moment(endTime).endOf('day'));
  return getApiServiceClient()
    .getAuditUserReportMetrics(req)
    .then((res: GetAuditUserReportMetricsResponse) => res.toObject());
};

export const registeredUserReport = (
  agencyUuid: string,
  studyUuid: string,
  startTime: string,
  endTime: string,
): Promise<GetAuditUserLoginMetricsResponse.AsObject> => {
  const req = new acst.GetAuditUserLoginMetricsRequest();
  req.setAgencyuuid(agencyUuid);
  req.setStudyuuid(studyUuid);
  req.setStarttime_asMoment(moment(startTime).startOf('day'));
  req.setEndtime_asMoment(moment(endTime).endOf('day'));

  return getApiServiceClient()
    .getAuditUserLoginMetrics(req)
    .then((res: GetAuditUserLoginMetricsResponse) => res.toObject());
};

export const getUserFromToken = async (): Promise<acst.GetUserFromTokenResponse.AsObject> => {
  return getApiServiceClient()
    .getUserFromToken(new acst.EmptyStructure())
    .then((res: acst.GetUserFromTokenResponse) => res.toObject());
};

export const replaceCustomShape = (
  saveasnewshape: boolean | undefined,
  shape: Record<string, any>,
): Promise<acst.CustomShapesAddResponse.AsObject | acst.CustomShapesAddResponse.AsObject> => {
  console.log({ saveasnewshape, shape });
  const mode = saveasnewshape || !shape.usershapeid ? 'Create' : 'Update';
  const req = mode === 'Create' ? new acst.CustomShapesAddRequest() : new acst.CustomShapesUpdateRequest();
  const shapeInfo =
    mode === 'Create'
      ? new acst.CustomShapesAddRequest.ShapeAddInfo()
      : new acst.CustomShapesUpdateRequest.ShapeUpdateInfo();
  shapeInfo.setUsershapeid(shape.usershapeid);
  shapeInfo.setLabel(shape.name);
  shapeInfo.setShowlabel(true);
  shapeInfo.setSharewithstudy(!!shape.sharewithstudy);
  shapeInfo.setSharewithagency(!!shape.sharewithagency);
  shapeInfo.setGeom(stringifyWkt(shape.geometry));

  const polygonStyle = new acst.PolygonVisualStyle();
  polygonStyle.setDefault(true);
  polygonStyle.setFillcolor(shape.style.fillColor);
  polygonStyle.setFillopacity(Math.trunc(shape.style.fillOpacity * 100));
  polygonStyle.setStrokecolor(shape.style.strokeColor);
  polygonStyle.setStrokewidth(shape.style.strokeWidth);
  polygonStyle.setStrokeopacity(Math.trunc(shape.style.strokeOpacity * 100));
  polygonStyle.setDescription(shape.style.type);
  shapeInfo.setVisualstyle(polygonStyle);

  const fontStyle = new acst.PolygonFontStyle();
  fontStyle.setFontsize(shape.labelProperties.fontSize);
  fontStyle.setFontcolor(shape.labelProperties.fontColor);
  fontStyle.setFontopacity(Math.trunc(shape.labelProperties.fontOpacity * 100));
  fontStyle.setHalocolor(shape.labelProperties.haloColor);
  fontStyle.setHaloopacity(Math.trunc(shape.labelProperties.haloOpacity * 100));
  fontStyle.setOffsetpositionx(shape.labelProperties.position[0]);
  fontStyle.setOffsetpositiony(shape.labelProperties.position[1]);
  shapeInfo.setFontstyle(fontStyle);

  req.setShape(shapeInfo);
  return mode === 'Create'
    ? getApiServiceClient()
        .customShapesAdd(req)
        .then((res: acst.CustomShapesAddResponse) => res.toObject())
    : getApiServiceClient().customShapesUpdate(req);
};

export const customShapesSearch = (): Promise<acst.CustomShapesSearchResponse.AsObject> => {
  const req = new acst.CustomShapesSearchRequest();
  return getApiServiceClient()
    .customShapesSearch(req)
    .then((res: CustomShapesSearchResponse) => res.toObject());
};

export const customShapesGet = (usershapeid: number): Promise<acst.CustomShapesGetResponse.AsObject> => {
  const req = new acst.CustomShapesGetRequest();
  req.setUsershapeid(usershapeid);
  return getApiServiceClient()
    .customShapesGet(req)
    .then((res: CustomShapesGetResponse) => res.toObject());
};

export const customShapesFix = (geometry: string): Promise<acst.CustomShapesFixResponse.AsObject> => {
  const req = new acst.CustomShapesFixRequest();
  req.setGeom(geometry);

  return getApiServiceClient()
    .customShapesFix(req)
    .then((res: acst.CustomShapesFixResponse) => res.toObject());
};

export const customShapesDelete = (usershapeid: number): Promise<acst.EmptyStructure> => {
  const req = new acst.CustomShapesDeleteRequest();
  req.setUsershapeid(usershapeid);

  return getApiServiceClient().customShapesDelete(req);
};

export const personTypeSearch = (
  studyUuid?: string,
  haspeopleonly = false,
): Promise<acst.PersonTypeSearchResponse.AsObject> => {
  const req = new acst.PersonTypeSearchRequest();
  req.setStudyuuid(studyUuid); //only used for study select filter
  req.setHaspeopleonly(haspeopleonly); //getting person types where this study uploaded people into
  return getApiServiceClient()
    .personTypeSearch(req)
    .then((res: acst.PersonTypeSearchResponse) => res.toObject());
};

export const personTypeGet = (personTypeId: number): Promise<acst.PersonTypeGetResponse.AsObject> => {
  const req = new acst.PersonTypeGetRequest();
  req.setPersontypeid(personTypeId);

  return getApiServiceClient()
    .personTypeGet(req)
    .then((res: acst.PersonTypeGetResponse) => res.toObject())
    .then((res: acst.PersonTypeGetResponse.AsObject) => {
      return {
        ...res,
        persontypeattributeList: res.persontypeattributeList.sort((a: any, b: any) => {
          if (a.datatype !== b.datatype) {
            if (a.datatype === attributeDataType.DATATYPE_NUMERIC) return -1;
            if (b.datatype === attributeDataType.DATATYPE_NUMERIC) return 1;
            if (a.datatype === attributeDataType.DATATYPE_STRING) return -1;
            if (b.datatype === attributeDataType.DATATYPE_STRING) return 1;
            if (a.datatype === attributeDataType.DATATYPE_BOOLEAN) return -1;
            if (b.datatype === attributeDataType.DATATYPE_BOOLEAN) return 1;
            if (a.datatype === attributeDataType.DATATYPE_DATETIME) return -1;
            if (b.datatype === attributeDataType.DATATYPE_DATETIME) return 1;
          }
          return a.name.localeCompare(b.name);
        }),
      };
    });
};

export const personTypeAdd = (
  name: string,
  studyUuid: string,
  attributes: any[],
): Promise<PersonTypeAddResponse.AsObject> => {
  const req = new acst.PersonTypeAddRequest();
  req.setPersontypename(name);
  req.setStudyuuid(studyUuid);
  req.setAgencylocationtypeid(1);

  const personTypeAttributesList: acst.PersonTypeAddRequest.PersonTypeAttribute[] = [];
  attributes.forEach(item => {
    const attribute = new acst.PersonTypeAddRequest.PersonTypeAttribute();
    attribute.setIsrequired(false);
    attribute.setPersontypeattributename(item.name);
    attribute.setDatatype(
      item.datatype === ATTRIBUTE_DATATYPE_LIST ? attributeDataType.DATATYPE_STRING : item.datatype,
    );
    if (item.values.length > 0) {
      attribute.setIsattributelist(true);
      const valueslist: Array<acst.PersonTypeAddRequest.PersonTypeAttribute.PredefinedValue> = [];

      item.values.forEach((a: any) => {
        const valueslistItem = new acst.PersonTypeAddRequest.PersonTypeAttribute.PredefinedValue();
        valueslistItem.setCode(String(a.code));
        valueslistItem.setValue(a.value);
        valueslist.push(valueslistItem);
      });

      attribute.setPredefinedvaluesList(valueslist);
    }
    personTypeAttributesList.push(attribute);
  });

  req.setPersontypeattributesList(personTypeAttributesList);

  return getApiServiceClient()
    .personTypeAdd(req)
    .then((res: PersonTypeAddResponse) => res.toObject());
};

export const personBulkAddInit = (
  personTypeId: number,
  studyUuid: string,
  records: number,
): Promise<PersonBulkAddInitResponse.AsObject> => {
  const req = new acst.PersonBulkAddInitRequest();
  req.setPersontypeid(personTypeId);
  req.setStudyuuid(studyUuid);
  req.setNumpersonrecords(records);
  return getApiServiceClient()
    .personBulkAddInit(req)
    .then((res: PersonBulkAddInitResponse) => res.toObject());
};

export const uploadPersonType = (
  personTypeId?: number,
  personUploadId?: number,
  personUploadHistoryId?: number,
  studyUuid?: string,
  people?: Record<string, any>[],
  attributes?: any[],
): Promise<acst.EmptyStructure> => {
  const createAttribute = (attr: any, value: any) => {
    const attribute = new acst.PersonBulkAddRequest.Person.CustomAttribute();
    attribute.setPersontypeattributeid(attr.id);
    attribute.setValue(value);
    return attribute;
  };

  const req = new acst.PersonBulkAddRequest();
  req.setPersontypeid(personTypeId);
  req.setPersonuploadid(personUploadId);
  req.setPersonuploadhistoryid(personUploadHistoryId);
  req.setStudyuuid(studyUuid);
  const peopleList: acst.PersonBulkAddRequest.Person = [];
  people?.forEach(item => {
    const person = new acst.PersonBulkAddRequest.Person();
    person.setAgencysuppliedpersonid(item.CustomerID);
    person.setFirstname(item.FirstName);
    person.setLastname(item.LastName);
    person.setPhonenumber(item.Phone);
    person.setEmail(item.Email);
    person.setAddress1(item.Address1);
    person.setCity(item.City);
    person.setAddress2(item.Address2);
    person.setState(item.State);
    person.setZipcode(item.ZipCode);

    const customAttributesList: acst.PersonBulkAddRequest.Person.CustomAttribute[] = [];
    attributes?.forEach(attr => {
      let value = item[attr.name];
      if (value !== undefined && value.trim() !== '') {
        const datatype = attr.datatype === ATTRIBUTE_DATATYPE_LIST ? attributeDataType.DATATYPE_STRING : attr.datatype;
        if (datatype === DataType.DATATYPE_BOOLEAN) {
          value = String(value).toLowerCase();
          if (['y', 'yes', 'true', '1', '+'].includes(value)) value = 'true';
          if (['n', 'no', 'false', '0', '-'].includes(value)) value = 'false';
          if (['true', 'false'].includes(value)) {
            customAttributesList.push(createAttribute(attr, value));
          }
        } else if (datatype === DataType.DATATYPE_DATETIME) {
          try {
            value = new Date(value).toISOString();
            customAttributesList.push(createAttribute(attr, value));
          } catch (error) {
            console.log(error);
          }
        } else if (datatype === DataType.DATATYPE_STRING) {
          const newValue = String(attr.values?.find((item: any) => +item.mappedcode === +value)?.value || value);
          customAttributesList.push(createAttribute(attr, newValue));
        } else {
          customAttributesList.push(createAttribute(attr, value));
        }
      }
    });
    if (customAttributesList.length > 0) {
      person.setCustomattributesList(customAttributesList);
    }
    peopleList.push(person);
  });
  req.setPeopleList(peopleList);

  return getApiServiceClient().personBulkAdd(req);
};

const personTypeLegend = (
  legend: ILegendRequest,
  features: Array<{ featureId: string; layerId: string }>,
  shapes: Array<string>,
): Promise<acst.PersonTypeGetResponse.AsObject> => {
  console.log({ legend });
  const req = preparePersonTypeLegendRequest(legend, features, shapes);

  const controller = AbortControllerStorage.get(AbortControllerKey.PeoplePlotLegend);

  return getApiServiceClient()
    .personTypeLegend(req, controller ? { signal: controller.signal } : {})
    .then((res: acst.PersonTypeLegendResponse) => res.toObject());
};

export const personTypeLegends = async (
  legendInfo: Array<ILegendRequest>,
  features: Array<{ featureId: string; layerId: string }>,
  shapes: Array<string>,
): Promise<Array<ILegend>> => {
  AbortControllerStorage.abort(AbortControllerKey.PeoplePlotLegend);
  AbortControllerStorage.register(AbortControllerKey.PeoplePlotLegend, new AbortController());

  const result = await Promise.allSettled(
    legendInfo.flatMap(async (legend: ILegendRequest) => {
      const data: ILegend = await personTypeLegend(legend, features, shapes).catch(() => []);
      return data;
    }),
  );

  return result.flatMap(res => (res.status === 'fulfilled' ? [res.value] : []));
};

export const personPlotGetInfo = async (
  legend: ILegendRequest,
  features: Array<{ featureId: string; layerId: string }>,
  shapes: Array<string>,
): Promise<acst.PersonPlotGetInfoResponse.AsObject> => {
  const req = preparePersonTypeLegendRequest(legend, features, shapes);

  return getApiServiceClient()
    .personPlotGetInfo(req)
    .then((res: acst.PersonPlotGetInfoResponse) => res.toObject());
};

export const getPersonInfo = async (id: string | number): Promise<PersonGetInfoResponse.AsObject> => {
  const req = new acst.PersonGetInfoRequest();

  req.setId(id);

  return getApiServiceClient()
    .personGetInfo(req)
    .then((res: PersonGetInfoResponse) => res.toObject());
};

export const userProfileUpdate = (data: Record<string, any>): Promise<acst.EmptyStructure> => {
  const req = new acst.UserProfileUpdateRequest();
  req.setFirstname(data.firstname);
  req.setLastname(data.lastname);
  req.setTitle(data.title);
  req.setShowwelcomescreen(data.showwelcomescreen);
  return getApiServiceClient().userProfileUpdate(req);
};

export const getUsersForImpersonation = (): Promise<acst.UsersForImpersonationResponse.AsObject> => {
  return getApiServiceClient()
    .getUsersForImpersonation(new acst.EmptyStructure())
    .then((res: acst.PersonTypeSearchResponse) => res.toObject());
};

export const userImpersonationLogin = (userUuid: string): Promise<acst.AuthTokens> => {
  const req = new acst.ImpersonationForLoginRequest();
  req.setUseruuid(userUuid);

  return getApiServiceClient()
    .tokenForImpersonationLoginGet(req)
    .then((res: acst.AuthTokens) => res.toObject());
};

export const mapSessionSearch = async (): Promise<acst.UserSessionSearchResponse.AsObject> => {
  return getApiServiceClient()
    .userSessionSearch(new acst.EmptyStructure())
    .then((res: acst.UserSessionSearchResponse) => res.toObject());
};

export const mapSessionGet = async (sessionId: number): Promise<acst.UserSessionGetResponse.AsObject> => {
  const req = new acst.UserSessionGetRequest();

  req.setUsersessiondatacacheid(sessionId);

  return getApiServiceClient()
    .userSessionGet(req)
    .then((res: acst.UserSessionGetResponse) => res.toObject());
};

export const mapSessionAdd = async ({
  name,
  jsonData,
}: {
  name: string;
  jsonData: string;
}): Promise<acst.UserSessionAddResponse.AsObject> => {
  const req = new acst.UserSessionAddRequest();

  req.setSessionname(name);
  req.setSessiondata(jsonData);

  return getApiServiceClient()
    .userSessionAdd(req)
    .then((res: acst.UserSessionAddResponse) => res.toObject());
};

export const mapSessionUpdate = async ({
  sessionId,
  jsonData,
}: {
  sessionId: number;
  jsonData: string;
}): Promise<acst.EmptyStructure> => {
  const req = new acst.UserSessionUpdateRequest();

  req.setUsersessiondatacacheid(sessionId);
  req.setSessiondata(jsonData);

  return getApiServiceClient().userSessionUpdate(req);
};

export const mapSessionDelete = async (sessionId: number): Promise<acst.EmptyStructure> => {
  const req = new acst.UserSessionDeleteRequest();
  req.setUsersessiondatacacheid(sessionId);

  return getApiServiceClient().userSessionDelete(req);
};

export const agencyLocationCalc = async (
  features: Array<{ featureId: string; layerId: string }>,
  shapes: Array<string>,
): Promise<acst.AgencyLocationCalcResponse> => {
  const req = new acst.AgencyLocationCalcRequest();
  const geometrySelection = convertToGeometrySelection({ features, shapes });
  if (geometrySelection) {
    req.setGeoms(geometrySelection);
  }

  AbortControllerStorage.abort(AbortControllerKey.AgencyLocation);
  const controller = AbortControllerStorage.register(AbortControllerKey.AgencyLocation, new AbortController());

  return await getApiServiceClient()
    .agencyLocationCalc(req, { signal: controller.signal })
    .then((response: acst.AgencyLocationCalcResponse) => response.toObject());
};

export const userSearch = async (): Promise<acst.UserSearchResponse.AsObject> => {
  const req = new acst.UserSearchRequest();
  return getApiServiceClient()
    .userSearch(req)
    .then((res: UserSearchResponse) => res.toObject());
};

export const userInfoGet = async (useruuid: string): Promise<acst.UserInfoGetResponse.AsObject> => {
  const req = new acst.UserInfoGetRequest();
  req.setUseruuid(useruuid);
  return getApiServiceClient()
    .userInfoGet(req)
    .then((res: acst.UserInfoGetResponse) => res.toObject());
};

export const userInviteLinkGet = async (): Promise<acst.UserInviteLinkGetResponse.AsObject> => {
  const req = new acst.EmptyStructure();
  return getApiServiceClient()
    .userInviteLinkGet(req)
    .then((res: acst.UserInviteLinkGetResponse) => res.toObject());
};

export const userPermissionUpdate = async (
  useruuid: string,
  grouplist: Array<number>,
): Promise<acst.EmptyStructure> => {
  const req = new acst.UserPermissionUpdateRequest();
  console.log('update permissions', useruuid, grouplist);
  req.setUseruuid(useruuid);
  req.setAclgroupidsList(grouplist);
  return getApiServiceClient().userPermissionUpdate(req);
};

export const userStudyAccessUpdate = async (
  useruuid: string,
  grouplist: Array<string>,
  allstudiesaccess: boolean | undefined,
): Promise<acst.EmptyStructure> => {
  const req = new acst.UserStudyAccessUpdateRequest();
  console.log(useruuid, grouplist, allstudiesaccess);

  req.setUseruuid(useruuid);
  if (allstudiesaccess === true) {
    console.log('case 1');
    req.setHasallstudyaccess(true);
    req.setStudyuuidsList([]);
  }
  if (allstudiesaccess === false) {
    console.log('case 2');
    req.setHasallstudyaccess(false);
    req.setStudyuuidsList(grouplist);
  }
  if (allstudiesaccess === undefined) {
    console.log('case 3');
    req.setStudyuuidsList(grouplist);
  }
  return getApiServiceClient().userStudyAccessUpdate(req);
};

export const updateUserStatus = async (mode: string, useruuid: string): Promise<string> => {
  const action = {
    deactivate: acst.UserRemovalAction.DEACTIVATE,
    delete: acst.UserRemovalAction.DELETE,
    activate: acst.UserRemovalAction.ACTIVATE,
  }[mode];
  const message = {
    deactivate: 'Successfully deactivated',
    delete: 'Successfully deleted',
    activate: 'Successfully activated',
  }[mode];

  const req = new acst.UserAgencyRemovalRequest();
  req.setUseruuid(useruuid);
  req.setAction(action);
  return getApiServiceClient()
    .userAgencyRemoval(req)
    .then(() => message);
};

export const userAclGroupSearch = (): Promise<acst.UserAclGroupSearchResponse.AsObject> => {
  const req = new acst.EmptyStructure();
  return getApiServiceClient()
    .userAclGroupSearch(req)
    .then((res: acst.UserAclGroupSearchResponse) => res.toObject());
};

export const agencyFileUploadSearch = (): Promise<acst.AgencyFileUploadSearchResponse.AsObject> => {
  const req = new acst.EmptyStructure();
  return getApiServiceClient()
    .agencyFileUploadSearch(req)
    .then((res: acst.AgencyFileUploadSearchResponse) => res.toObject());
};

export const agencyFileUploadDownload = (id: number): Promise<acst.AgencyFileUploadDownloadResponse.AsObject> => {
  const req = new acst.AgencyFileUploadDownloadRequest();
  req.setAgencyfileuploadid(id);
  return getApiServiceClient()
    .agencyFileUploadDownload(req)
    .then((res: acst.AgencyFileUploadDownloadResponse) => res.toObject());
};

export const agencyFileUploadDelete = (id: number): Promise<acst.EmptyStructure> => {
  const req = new acst.AgencyFileUploadDeleteRequest();
  req.setAgencyfileuploadid(id);
  return getApiServiceClient().agencyFileUploadDelete(req);
};

export const agencyFileUploadAdd = (payload: any): Promise<acst.EmptyStructure> => {
  console.log(payload);
  const req = new acst.AgencyFileUploadAddRequest();
  req.setFilename(payload.filename);
  req.setFiledescription(payload.filedescription);
  req.setFilecomment(payload.filecomment);
  req.setFiledata(payload.filedata);
  return getApiServiceClient().agencyFileUploadAdd(req);
};

export const getThematicVariables = (
  type: ReportCategoryMap[keyof ReportCategoryMap],
): Promise<acst.ReportCategorySelectResponse.AsObject> => {
  const req = new acst.ReportCategorySelectRequest();
  req.setCategory(type);
  return getApiServiceClient()
    .reportCategorySelect(req)
    .then((res: acst.ReportCategorySelectResponse) => res.toObject())
    .then((res: acst.ReportCategorySelectResponse.AsObject) => {
      console.log({ res, type });
      return res;
    });
};

export const getThematicReportTilesets = (variableId: number): Promise<acst.ReportTilesetResponse.AsObject> => {
  const req = new acst.ReportTilesetRequest();
  req.setVariableid(variableId);

  return getApiServiceClient()
    .reportTileset(req)
    .then((res: acst.ReportTilesetResponse) => res.toObject());
};

export const getABSVariables = (): Promise<acst.ReportCategoryAbsSelectResponse.AsObject> => {
  const req = new acst.EmptyStructure();
  return getApiServiceClient()
    .reportAbsCategorySelect(req)
    .then((res: acst.ReportCategoryAbsSelectResponse) => res.toObject());
};

export const getThematicLegend = (
  variableId: string,
  geometry: LegendGeometry,
): Promise<acst.ThematicLegendResponse.AsObject> => {
  const req = new acst.ThematicLegendRequest();
  req.setVariableid(variableId);

  const geometrySelection = convertToGeometrySelection(geometry);
  if (geometrySelection) {
    req.setGeoms(geometrySelection);
  }
  return getApiServiceClient()
    .thematicLegend(req)
    .then((res: acst.ThematicLegendResponse) => res.toObject());
};

export const reportPredefinedList = (): Promise<acst.ReportPredefinedListResponse.AsObject> => {
  const req = new acst.EmptyStructure();
  return getApiServiceClient()
    .reportPredefinedList(req)
    .then((res: acst.ReportPredefinedListResponse) => res.toObject());
};

export interface ReportJobPayload {
  reportfiletype: acst.ReportFileTypeMap[keyof acst.ReportFileTypeMap];
  reporttype: acst.ReportTypeMap[keyof acst.ReportTypeMap];
  reportdata: Record<string, any>;
  studyArea: string;
  features: Array<{ featureId: string; layerId: string }>;
  shapes: Array<string>;
  image?: string;
  legend?: Array<ILegendRequest>;
}

const preparePersonTypeLegendRequest = (
  legend: ILegendRequest,
  features: Array<{ featureId: string; layerId: string }>,
  shapes: Array<string>,
) => {
  const req = new acst.PersonTypeLegendRequest();
  const geometrySelection = convertToGeometrySelection({ features, shapes });
  if (geometrySelection) {
    req.setGeoms(geometrySelection);
  }

  req.setPersontypeid(legend.personTypeId);
  req.setPersontypeattributeid(legend.attributeId);
  req.setStudyuuidsList(legend.studyUuids);

  console.log({ legend });

  const operatorsMap = { equals: 0, less: 1, greater: 2, lessOrEqual: 3, greaterOrEqual: 4, notEquals: 5 };

  if (legend.stringFilterList.length) {
    const stringAttributesList: Array<acst.PersonTypeLegendRequest.StringFilter> = [];
    legend.stringFilterList.forEach(item => {
      item.data.forEach(value => {
        const attribute = new acst.PersonTypeLegendRequest.StringFilter();
        attribute.setPersontypeattributeid(item.attributeId);
        attribute.setData(value);
        stringAttributesList.push(attribute);
      });
    });
    req.setStringfilterList(stringAttributesList);
  }

  if (legend.numericFilterList.length) {
    const numericAttributesList: Array<acst.PersonTypeLegendRequest.NumericFilter> = [];
    legend.numericFilterList.forEach(item => {
      const attribute = new acst.PersonTypeLegendRequest.NumericFilter();
      attribute.setPersontypeattributeid(item.attributeId);
      attribute.setOperator(operatorsMap[item.operator]);
      attribute.setData(item.data);
      numericAttributesList.push(attribute);
    });
    req.setNumericfilterList(numericAttributesList);
  }

  if (legend.datetimeFilterList.length) {
    const dateAttributesList: Array<acst.PersonTypeLegendRequest.DateTimeFilter> = [];
    legend.datetimeFilterList.forEach(item => {
      const attribute = new acst.PersonTypeLegendRequest.DateTimeFilter();
      attribute.setPersontypeattributeid(item.attributeId);
      attribute.setOperator(operatorsMap[item.operator]);
      attribute.setData_asMoment(moment(item.data));
      dateAttributesList.push(attribute);
    });
    req.setDatetimefilterList(dateAttributesList);
  }

  if (legend.booleanFilterList.length) {
    const booleanAttributesList: Array<acst.PersonTypeLegendRequest.BooleanFilter> = [];
    legend.booleanFilterList.forEach(item => {
      const attribute = new acst.PersonTypeLegendRequest.BooleanFilter();
      attribute.setPersontypeattributeid(item.attributeId);
      attribute.setData(item.data);
      booleanAttributesList.push(attribute);
    });
    req.setBooleanfilterList(booleanAttributesList);
  }
  return req;
};

export const reportJobCreate = (payload: ReportJobPayload): Promise<acst.ReportJobCreateResponse.AsObject> => {
  console.log({ payload });
  const req = new acst.ReportJobCreateRequest();
  req.setReportfiletype(payload.reportfiletype);
  req.setStudyarea(payload.studyArea);
  if (payload.reporttype === ReportType.REPORTTYPE_PREDEFINED) {
    const report = new acst.ReportJobCreateRequest.PredefinedReportInfo();
    report.setPreparedfor(payload.reportdata.preparedfor);
    report.setPredefinedreportid(payload.reportdata.report);
    req.setMapimagebase64(payload.image);
    if (payload.reportdata.report === predefinedReportId.PREDEFINEDREPORTID_DONORSUMMARYREPORT) {
      console.log('set person', payload.reportdata.personid);
      report.setPersonid(payload.reportdata.personid);
    }
    if (payload.reportdata.report === predefinedReportId.PREDEFINEDREPORTID_COMPARATIVEINSITE && payload.legend) {
      const infolist: any = [];
      payload.legend?.forEach(legend => {
        infolist.push(preparePersonTypeLegendRequest(legend, payload.features, payload.shapes));
      });

      report.setComparativeinsiteinfoList(infolist);
    }
    req.setPredefinedreport(report);
  }
  if (payload.reporttype === ReportType.REPORTTYPE_CUSTOM_DEMOGRAPHICS) {
    const customDemographicsReportInfo = new acst.ReportJobCreateRequest.CustomDemographicsReportInfo();
    customDemographicsReportInfo.setReportidsList(payload.reportdata.ids);
    req.setMapimagebase64(payload.image);
    req.setCustomdemographicsreport(customDemographicsReportInfo);
  }

  if (payload.reporttype === ReportType.REPORTTYPE_CUSTOM_AMERICANBELIEFSTUDY) {
    const customABSReportInfo = new acst.ReportJobCreateRequest.CustomAmericanBeliefStudyReportInfo();
    customABSReportInfo.setGroupidsList(payload.reportdata.ids);
    req.setMapimagebase64(payload.image);
    req.setCustomamericanbeliefstudyreport(customABSReportInfo);
  }

  req.setReporttype(payload.reporttype);
  console.log(payload.features, payload.shapes);
  if (payload.features.length > 0 || payload.shapes.length > 0) {
    const geometrySelection = convertToGeometrySelection({ features: payload.features, shapes: payload.shapes });

    req.setGeoms(geometrySelection);
  }

  return getApiServiceClient().reportJobCreate(req);
};

export const createOppScanReport = (payload: {
  features: Array<{ featureId: string; layerId: string }>;
  shapes: Array<string>;
  reportdata: {
    variableid: number;
    scanby: number;
  };
}): Promise<acst.GetOppScanInfoResponse.AsObject> => {
  console.log(payload);

  const req = new acst.GetOppScanInfoRequest();
  const geometrySelection = convertToGeometrySelection({ features: payload.features, shapes: payload.shapes });
  req.setGeom(geometrySelection);
  req.setVariableid(payload.reportdata.variableid);
  req.setScanby(payload.reportdata.scanby);

  return getApiServiceClient()
    .getOppScanInfo(req)
    .then((res: acst.GetOppScanInfoResponse) => res.toObject());
};

export const verifyRegistrationCode = (code: string): Promise<acst.VerifyRegistrationCodeResponse.AsObject> => {
  const req = new acst.VerifyRegistrationCodeRequest();
  req.setRegistrationcode(code);
  return getApiServiceClient()
    .verifyRegistrationCode(req)
    .then((res: acst.VerifyRegistrationCodeResponse) => res.toObject());
};

export const applyRegistrationCode = (code: string, locationid: string): Promise<acst.EmptyStructure> => {
  console.log({ code, locationid });
  const req = new acst.ApplyRegistrationCodeRequest();
  req.setRegistrationcode(code);
  req.setLocationid(locationid);
  return getApiServiceClient().applyRegistrationCode(req);
};

export async function getNeighborCenterFilters() {
  const req = new acst.EmptyStructure();

  return getApiServiceClient()
    .getNeighborCenterFilters(req)
    .then((res: acst.GetNeighborCenterFiltersResponse) => res.toObject());
}

export async function getNeighborCenterLegend(legend: LegendRequest, geometry: LegendGeometry) {
  const req = new acst.GetNeighborCenterLegendRequest();

  if (legend.legendId) req.setLegendby(legend.legendId);

  const geometrySelection = convertToGeometrySelection(geometry);
  if (geometrySelection) req.setGeoms(geometrySelection);

  const filterList = getNeighborCenterFiltersForLegend(legend);
  req.setFilterlistList(filterList);

  AbortControllerStorage.abort(AbortControllerKey.NeighborsLegend);
  const controller = AbortControllerStorage.register(AbortControllerKey.NeighborsLegend, new AbortController());

  return getApiServiceClient()
    .getNeighborCenterLegend(req, { signal: controller.signal })
    .then((res: acst.GetNeighborCenterLegendResponse) => res.toObject());
}

function getNeighborCenterFiltersForLegend(legend: LegendRequest): Array<acst.NeighborCenterFilter> {
  return legend.filterList.map(filter => {
    const attribute = new acst.NeighborCenterFilter();
    attribute.setId(filter.attributeId);
    attribute.setValueslistList(filter.codes);
    return attribute;
  });
}

interface IGetNeighborCenterTotalRequest {
  features: Array<{ featureId: string; layerId: string }>;
  shapes: Array<string>;
  filters: Array<{ id: string; codes: string[] }>;
}

export async function getNeighborCenterTotal({
  features,
  shapes,
  filters,
}: IGetNeighborCenterTotalRequest): Promise<acst.GetNeighborCenterTotalResponse.AsObject> {
  const req = new acst.GetNeighborCenterTotalRequest();

  const geometrySelection = convertToGeometrySelection({ features, shapes });

  if (geometrySelection) {
    req.setGeoms(geometrySelection);
  }

  const filtersList = filters.map(filter => {
    const filterList = new acst.NeighborCenterFilter();

    filterList.setId(filter.id);
    filterList.setValueslistList(filter.codes);

    return filterList;
  });

  req.setFilterlistList(filtersList);

  return getApiServiceClient()
    .getNeighborCenterTotal(req)
    .then((res: acst.GetNeighborCenterTotalResponse) => res.toObject());
}

export async function getHouseholdInfo(id: number): Promise<acst.GetHouseholdInfoResponse.AsObject> {
  const req = new acst.GetHouseholdInfoRequest();

  req.setId(id);

  return getApiServiceClient()
    .getHouseholdInfo(req)
    .then((res: acst.GetHouseholdInfoResponse) => res.toObject());
}

export async function getDonorContributionReport(
  features: Array<{ featureId: string; layerId: string }>,
  shapes: Array<string>,
): Promise<acst.GetDonorContributionReportResponse.AsObject> {
  const req = new acst.DonorContributionReportRequest();
  const geometrySelection = convertToGeometrySelection({ features, shapes });
  if (geometrySelection) {
    req.setGeom(geometrySelection);
  }
  return getApiServiceClient()
    .getDonorContributionReport(req)
    .then((res: acst.GetDonorContributionReportResponse) => res.toObject());
}

export async function getDonorCountsByFusionViewCategories({
  features,
  shapes,
}: {
  features: Array<{ featureId: string; layerId: string }>;
  shapes: Array<string>;
}): Promise<acst.DonorCountsByFusionViewResponse.AsObject> {
  const req = new acst.DonorCountsByFusionViewRequest();
  if (features.length > 0 || shapes.length > 0) {
    const geometrySelection = convertToGeometrySelection({ features, shapes });
    req.setGeom(geometrySelection);
  }
  return getApiServiceClient()
    .getDonorCountsByFusionViewCategories(req)
    .then((res: acst.DonorCountsByFusionViewResponse) => res.toObject());
}

export async function getDonorsByFilters({
  fusionviewcategoryid = -1,
  givingpotentialid = -1,
  page = 1,
  contributionCategoryTypeIds = [],
  contributorTypeId = -1,
  geoms = { features: [], shapes: [] },
}: {
  fusionviewcategoryid?: number;
  givingpotentialid?: number;
  page?: number;
  contributionCategoryTypeIds?: Array<number>;
  contributorTypeId?: number;
  geoms?: {
    features: Array<{ featureId: string; layerId: string }>;
    shapes: Array<string>;
  };
}): Promise<acst.DonorsByFiltersResponse.AsObject> {
  const req = new acst.GetDonorsByFiltersRequest();
  if (fusionviewcategoryid) req.setFusionviewcategoryid(fusionviewcategoryid);
  if (givingpotentialid) req.setGivingpotentialid(givingpotentialid);
  req.setPagenumber(page);

  if (contributionCategoryTypeIds.length) {
    req.setContributioncategorytypeidList(contributionCategoryTypeIds);
  }
  if (contributorTypeId !== -1) {
    req.setContributortypeid(contributorTypeId);
  }

  const geometrySelection = convertToGeometrySelection(geoms);
  if (geometrySelection) {
    req.setGeoms(geometrySelection);
  }
  return getApiServiceClient()
    .getDonorsByFilters(req)
    .then((res: acst.DonorsByFiltersResponse) => res.toObject());
}

export async function getLatestUploadedDonors(): Promise<acst.DonorListResponse.AsObject> {
  const req = new acst.EmptyStructure();

  return getApiServiceClient()
    .getLatestUploadedDonors(req)
    .then((res: acst.DonorListResponse) => res.toObject());
}

export async function getThematicPopupInfo(
  level: acst.Level,
  shapeId: string,
  variableId: number,
): Promise<acst.PopupInfoGetResponse.AsObject> {
  const req = new acst.PopupInfoGetRequest();
  req.setShapeid(shapeId);
  req.setLayer(level);
  req.setVariableid(variableId);

  return getApiServiceClient()
    .popupInfoGet(req)
    .then((res: acst.PopupInfoGetResponse) => res.toObject());
}

export default getApiServiceClient;

export async function getSampleAddressList(): Promise<acst.GetSampleAddressListResponse.AsObject> {
  const req = new acst.EmptyStructure();

  return getApiServiceClient()
    .getSampleAddressList(req)
    .then((res: acst.GetSampleAddressListResponse) => res.toObject());
}

interface IGetFullAddressListRequest {
  features: Array<{ featureId: string; layerId: string }>;
  shapes: Array<string>;
  filters: Array<{ id: string; codes: string[] }>;
  limit?: number;
}

export async function getFullAddressList({
  features,
  shapes,
  filters,
  limit,
}: IGetFullAddressListRequest): Promise<acst.GetFullAddressListResponse.AsObject> {
  const req = new acst.GetFullAddressListRequest();

  const geometrySelection = convertToGeometrySelection({ features, shapes });

  if (geometrySelection) {
    req.setGeoms(geometrySelection);
  }

  const filtersList = filters.map(filter => {
    const filterList = new acst.NeighborCenterFilter();

    filterList.setId(filter.id);
    filterList.setValueslistList(filter.codes);

    return filterList;
  });

  req.setFilterlistList(filtersList);

  if (limit) {
    req.setReturnlimit(limit);
  }

  return getApiServiceClient()
    .getFullAddressList(req)
    .then((res: acst.GetFullAddressListResponse) => res.toObject());
}

const getCustomClient =
  (token: string) =>
  (url: string, body?: Uint8Array, config?: object): Promise<Uint8Array> => {
    const localConfig: RequestOptions = {
      method: 'POST',
      headers: {
        'content-type': 'application/protobuf',
        Authorization: token,
      },
    };

    return fetch(url, { body, ...config, ...localConfig } as any)
      .then(response => {
        if (!response.ok) {
          throw new ResponseError(response.status, response);
        }
        return response.arrayBuffer();
      })
      .then(buffer => new Uint8Array(buffer));
  };

export async function userApproveDenyCheck(token: string): Promise<acst.UserApproveDenyCheckResponse.AsObject> {
  const apiServiceCallWrapper = getCustomClient(token);
  const apiClient = new acst.ApiserviceClient(apiServiceCallWrapper, config.apiServiceUrl);

  return apiClient
    .userApproveDenyCheck(new acst.EmptyStructure())
    .then((res: acst.UserApproveDenyCheckResponse) => res.toObject());
}

export async function userApproveDeny(token: string, action: number): Promise<acst.UserApproveDenyResponse.AsObject> {
  const apiServiceCallWrapper = getCustomClient(token);
  const apiClient = new acst.ApiserviceClient(apiServiceCallWrapper, config.apiServiceUrl);

  const req = new acst.UserApproveDenyRequest();
  req.setAction(action);
  return apiClient.userApproveDeny(req).then((res: acst.UserApproveDenyResponse) => res.toObject());
}

export async function downloadAwsFile(link: string): Promise<Blob> {
  return fetch(link).then(response => response.blob());
}

export async function locationGeoUpdate(locationId: number, geoData: { latitude: number; longitude: number }) {
  const request = new acst.LocationGeoData();

  request.setLocationid(locationId);
  request.setLatitude(geoData.latitude);
  request.setLongitude(geoData.longitude);

  return getApiServiceClient()
    .locationGeoUpdate(request)
    .then((res: acst.EmptyStructure) => res.toObject());
}
