import { ref } from 'vue';
import { defineStore } from 'pinia';

import { ISource } from '@/types/map';
import { searchLayerFeatures } from '@/services/api.service';

import useGlobalStore from '@/stores/global';

import type { TBoundarieshMap, TGeoDetails } from '@/types/agency';
import type { SelectedFeatureId } from '@/types/selection';

const useBoundariesStore = defineStore('boundaries', () => {
  const boundariesDict = ref<TBoundarieshMap>({});

  async function getFeatureBoundaries(source: ISource, featureId: SelectedFeatureId): Promise<TGeoDetails> {
    const globalStore = useGlobalStore();

    const id = getId(source, featureId);
    if (!boundariesDict.value[id]) {
      const features = await searchLayerFeatures(globalStore.study?.uuid, source, 'id', `${featureId}`);
      if (features.resultList.length > 0) {
        addFeature(source, features.resultList[0].id, {
          bbox: features.resultList[0].bboxList,
          area: features.resultList[0].area,
          perimeter: features.resultList[0].perimeter,
        });
      }
    }
    return Promise.resolve(boundariesDict.value[id]);
  }

  function addFeature(source: ISource, featureId: SelectedFeatureId, geoDetails: TGeoDetails) {
    const id = getId(source, featureId);
    boundariesDict.value[id] = geoDetails;
  }

  function getId(source: ISource, featureId: SelectedFeatureId): string {
    if (source.type === 'geojson') {
      return `${source.id}::${featureId}`;
    }
    return `${source.id}::${source.layer}::${featureId}`;
  }

  return {
    boundariesDict,

    getFeatureBoundaries,
    addFeature,
  };
});

export default useBoundariesStore;
