import { ref, shallowRef } from 'vue';
import { defineStore } from 'pinia';

import { MapInteractionMode, type ISource, type TCoord, type TMap, type TMarker } from '@/types/map';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { getSourceData } from '@/services/datasource.service';
import useTasksStore from '@/stores/tasks';
import useBoundariesStore from '@/stores/boundaries';
import useSelectionStore from './selection';
import mapboxgl, { FitBoundsOptions, LngLatBoundsLike } from 'mapbox-gl';
import { DRAWING_MODES } from '@/types/shape';

import type { SelectedFeatureId } from '@/types/selection';

const useMapStore = defineStore('map', () => {
  const map = shallowRef<null | TMap>(null);
  const marker = shallowRef<null | TMarker>(null);
  const mapLoaded = ref<boolean>(false);
  const searchMarkers = ref<Array<TMarker>>([]);
  const oppscanMarkers = ref<Array<TMarker>>([]);

  const interactionMarker = ref<mapboxgl.Marker | null>(null);
  const mapInteractionMode = ref<MapInteractionMode>(MapInteractionMode.selectSingle);
  const drawControl = shallowRef<null | MapboxDraw>(null);

  const shouldRenderOffscreenMap = ref<boolean>(false);
  const offscreenMapZoomIn = ref<boolean>(false);
  const offscreenMapLoaded = ref<boolean>(false);

  function setMap(mapToSet: TMap) {
    map.value = mapToSet;
  }

  function setSearchMarkers(markers: Array<TMarker>) {
    searchMarkers.value = markers;
  }

  function setMarker(markerToSet: TMarker) {
    marker.value = markerToSet;
  }

  function toggleMarker(visibility: 'visible' | 'hidden') {
    //@ts-ignore
    if (marker.value?._element) marker.value._element.style.visibility = visibility;
  }

  function moveMarker(lngLat: TCoord) {
    if (map.value) marker.value?.setLngLat(lngLat).addTo(map?.value);
  }

  function setMapLoaded(loaded: boolean) {
    mapLoaded.value = loaded;
  }

  function setDrawControl(controlToSet: MapboxDraw) {
    drawControl.value = controlToSet;
  }

  function setInteractionMarker(marker: mapboxgl.Marker | null) {
    if (interactionMarker.value) interactionMarker.value.remove();
    interactionMarker.value = marker;
  }

  function setMapInteractionMode(mode: MapInteractionMode) {
    const selectionStore = useSelectionStore();
    selectionStore.setDrawMode(DRAWING_MODES.simpleSelect);
    drawControl.value?.changeMode(DRAWING_MODES.simpleSelect);

    setInteractionMarker(null);
    mapInteractionMode.value = mode;
  }

  async function loadDataSource(source: ISource, layerName: string) {
    const tasksStore = useTasksStore();

    if (!map.value || source.type !== 'geojson') {
      return;
    }
    const mapSource = map?.value.getSource(source.id);
    if (!mapSource || source.loaded) {
      return;
    }

    tasksStore.startTask({ name: `Loading ${layerName} layer...`, identifier: `load-layer-${source.id}` });
    const data = await getSourceData(source);
    (mapSource as mapboxgl.GeoJSONSource).setData(data);
    source.loaded = true;
    tasksStore.completeTask(`load-layer-${source.id}`);
  }

  async function getFeatureBoundaries(
    source: ISource,
    featureId: SelectedFeatureId,
  ): Promise<GeoJSON.BBox | undefined> {
    const boundariesStore = useBoundariesStore();
    const { bbox } = await boundariesStore.getFeatureBoundaries(source, featureId);

    return bbox;
  }

  async function fitFeature(source: ISource, featureId: SelectedFeatureId, options?: FitBoundsOptions) {
    if (!map.value) return;

    const { padding = 20, ...otherOptions } = options || {};

    const bbox = await getFeatureBoundaries(source, featureId);
    if (bbox) map.value.fitBounds(bbox as LngLatBoundsLike, { padding, ...otherOptions });
  }

  return {
    marker,
    map,
    mapLoaded,
    drawControl,
    searchMarkers,
    oppscanMarkers,

    shouldRenderOffscreenMap,
    offscreenMapZoomIn,
    offscreenMapLoaded,

    setMarker,
    toggleMarker,
    moveMarker,
    setMap,
    setMapLoaded,
    setDrawControl,
    loadDataSource,
    fitFeature,
    getFeatureBoundaries,
    setSearchMarkers,
    interactionMarker,
    setInteractionMarker,
    mapInteractionMode,
    setMapInteractionMode,
  };
});

export default useMapStore;
